export default {
    0: {
        top: 1,
        topRight: 1,
        topLeft: 1,
        middle: 0,
        bottomRight: 1,
        bottomLeft: 1,
        bottom: 1,
    },
    1: {
        top: 0,
        topRight: 1,
        topLeft: 0,
        middle: 0,
        bottomRight: 1,
        bottomLeft: 0,
        bottom: 0,
    },
    2: {
        top: 1,
        topRight: 1,
        topLeft: 0,
        middle: 1,
        bottomRight: 0,
        bottomLeft: 1,
        bottom: 1,
    },
    3: {
        top: 1,
        topRight: 1,
        topLeft: 0,
        middle: 1,
        bottomRight: 1,
        bottomLeft: 0,
        bottom: 1,
    },
    4: {
        top: 0,
        topRight: 1,
        topLeft: 1,
        middle: 1,
        bottomRight: 1,
        bottomLeft: 0,
        bottom: 0,
    },
    5: {
        top: 1,
        topRight: 0,
        topLeft: 1,
        middle: 1,
        bottomRight: 1,
        bottomLeft: 0,
        bottom: 1,
    },
    6: {
        top: 1,
        topRight: 0,
        topLeft: 1,
        middle: 1,
        bottomRight: 1,
        bottomLeft: 1,
        bottom: 1,
    },
    7: {
        top: 1,
        topRight: 1,
        topLeft: 0,
        middle: 0,
        bottomRight: 1,
        bottomLeft: 0,
        bottom: 0,
    },
    8: {
        top: 1,
        topRight: 1,
        topLeft: 1,
        middle: 1,
        bottomRight: 1,
        bottomLeft: 1,
        bottom: 1,
    },
    9: {
        top: 1,
        topRight: 1,
        topLeft: 1,
        middle: 1,
        bottomRight: 1,
        bottomLeft: 0,
        bottom: 0,
    },
}
